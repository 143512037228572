import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faTable, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
 
const FilterSection = ({ startDate, setStartDate, handlePreviousDay, handleNextDay, searchTerm, setSearchTerm, view, setView, handleApiResponse, setLoading, isValidIP, setIsValidIP }) => {
  const [response, setResponse] = useState(null);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
 
  const handleGoBack = () => {
    navigate(-1);
  };
 
  const handleIPChange = (e) => {
    const ip = e.target.value;
    setSearchTerm(ip);
 
    const isValidIPv4 = (ip) => {
      const [address, prefix] = ip.split('/');
      const segments = address.split('.');
      if (segments.length === 4 && (!prefix || (Number(prefix) >= 0 && Number(prefix) <= 32))) {
        return segments.every(segment => /^\d+$/.test(segment) && Number(segment) >= 0 && Number(segment) <= 255 &&
          (segment === "0" || !segment.startsWith("0")));
      }
      return false;
    };
 
    const isValidIPv6 = (ip) => {
      const [address, prefix] = ip.split('/');
      const segments = address.split(':');
      if (segments.length <= 8 && (!prefix || (Number(prefix) >= 0 && Number(prefix) <= 128))) {
        return segments.every(segment => segment === "" || /^[0-9a-fA-F]{1,4}$/.test(segment));
      }
      return false;
    };
 
    setIsValidIP(ip.includes('.') ? isValidIPv4(ip) : ip.includes(':') && isValidIPv6(ip));
  };
 
  const formatDate = (date) => date ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}` : null;
 
  const handleSearchClick = () => {
    setLoading(true);
    const formattedDate = startDate.toISOString().split("T")[0];
    console.log("Search Term:", searchTerm);
    let apiUrl = "";
    if (searchTerm.includes("/")) {
      if (searchTerm.includes(":")) {
        apiUrl = `https://api.manycast.net/ipv6/prefix/${formattedDate}?prefix=${searchTerm}`;
      } else {
        apiUrl = `https://api.manycast.net/ipv4/prefix/${formattedDate}?prefix=${searchTerm}`;
      }
    } else if (!searchTerm.includes("?")) {
      if (searchTerm.includes(":")) {
        apiUrl = `https://api.manycast.net/ipv6/${formattedDate}?prefix=${searchTerm}`;
      } else {
        apiUrl = `https://api.manycast.net/ipv4/${formattedDate}?prefix=${searchTerm}`;
      }
    }
    fetch(apiUrl)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Data not found in the database");
        }
        return res.json();
      })
      .then((data) => {
        console.log("API Response:", data);
        setResponse(data);  
        handleApiResponse(data);
        setError(null);
      })
      .catch((error) => {
        console.error("Error fetching the API:", error);
        setError(error.message);
        handleApiResponse({
          prefix: "",
          count: 0,
          characterization: {},
          instances: []
      })})
      .finally(() => {
        setLoading(false); 
      });
  };
 
  return (
    <div className="filter-section">
      <div className="filter-item">
        <label>Select Date:</label>
        <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
      </div>
      <div className="filter-item">
        <button onClick={handlePreviousDay}>Previous Day</button>
        <button onClick={handleNextDay}>Next Day</button>
      </div>
      <div className="filter-item search-wrapper">
        <label>Enter Prefix:</label>
        <input type="text" placeholder="Prefix" onChange={handleIPChange} value={searchTerm} />
        <button className="search-button" onClick={handleSearchClick}>
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </div>
      <div className="toggle-container">
        <label className={`toggle-switch ${view === "table" ? "on" : ""}`}>
          <input type="checkbox" checked={view === "table"} onChange={() => setView(view === "map" ? "table" : "map")} />
          <span className="toggle-slider"></span>
          <FontAwesomeIcon icon={faMap} className="toggle-icon map-icon" />
          <FontAwesomeIcon icon={faTable} className="toggle-icon table-icon" />
        </label>
       
      </div>
      <div className={isValidIP ? "valid-ip" : "invalid-ip"}>{isValidIP ? "✅ Prefix entry is valid" : "❌ Prefix entry is not valid"}</div>
        <button onClick={handleGoBack} className="go-back-button">⬅️ Stats</button>
        {error && <div className="error-message">{'⚠️' + error}</div>}
    </div>
  );
};
 
export default FilterSection;
